import React from "react";
import { Theme, makeStyles, IconButton, Typography, Button, Zoom } from "@material-ui/core";
import { KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons";

interface ControlBoxProps {
  show: boolean;
  roundTimeSeconds: number;
  onIncrementMinutesButtonClick: () => void;
  onDecrementMinutesButtonClick: () => void;
  onIncrementSecondsButtonClick: () => void;
  onDecrementSecondsButtonClick: () => void;
  inProgress: boolean;
  toggleInProgress: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  roundTimeContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  roundTimeComponent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  roundTimeWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const ControlBox: React.FC<ControlBoxProps> = (props) => {
  const classes = useStyles();

  return (
    <Zoom in={props.show} unmountOnExit>
      <div className={classes.root}>
        <Button color="primary" onClick={props.toggleInProgress}>
          {props.inProgress ? "End" : "Start"}
        </Button>
        <div className={classes.roundTimeWrapper}>
          <div className={classes.roundTimeContainer}>
            <div className={classes.roundTimeComponent}>
              <IconButton size="small" onClick={props.onIncrementMinutesButtonClick}>
                <KeyboardArrowUp />
              </IconButton>
              <Typography variant="h5">{pad(getMinutes(props.roundTimeSeconds))}</Typography>
              <IconButton size="small" onClick={props.onDecrementMinutesButtonClick}>
                <KeyboardArrowDown />
              </IconButton>
            </div>
            <Typography variant="h5">:</Typography>
            <div className={classes.roundTimeComponent}>
              <IconButton size="small" onClick={props.onIncrementSecondsButtonClick}>
                <KeyboardArrowUp />
              </IconButton>
              <Typography variant="h5">{pad(getSeconds(props.roundTimeSeconds))}</Typography>
              <IconButton size="small" onClick={props.onDecrementSecondsButtonClick}>
                <KeyboardArrowDown />
              </IconButton>
            </div>
          </div>
          <Typography variant="subtitle2">Round Time</Typography>
        </div>
      </div>
    </Zoom>
  );
};

const getMinutes = (totalSeconds: number): string => {
  return `${Math.trunc(totalSeconds / 60)}`;
};

const getSeconds = (totalSeconds: number): string => {
  return `${totalSeconds % 60}`;
};

export const pad = (num: string): string => {
  return num.length < 2 ? `0${num}` : num;
};

export const formatTime = (totalSeconds: number): string => {
  return `${pad(getMinutes(totalSeconds))} : ${pad(getSeconds(totalSeconds))}`;
};

export default ControlBox;
