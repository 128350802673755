import React, { useState } from "react";
import { Theme, makeStyles, IconButton, Typography, Button, TextField, Zoom } from "@material-ui/core";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { Edit, ImportExport, KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons";

interface EstimateProps {
  show: boolean;
  onSubmitEstimate: (estimate: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100%",
    minHeight: "200px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  inputEstimate: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  arrowsEstimate: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const Estimate: React.FC<EstimateProps> = (props) => {
  const classes = useStyles();

  const [estimate, setEstimate] = useState(0);
  const [editMode, setEditMode] = useState<"input" | "arrows">("arrows");

  return (
    <Zoom in={props.show} unmountOnExit>
      <div className={classes.root}>
        <ToggleButtonGroup
          exclusive
          value={editMode}
          onChange={(event: React.MouseEvent<HTMLElement>, mode: string | null) =>
            setEditMode(mode === "input" ? "input" : "arrows")
          }
        >
          <ToggleButton value="input" size="small">
            <Edit />
          </ToggleButton>
          <ToggleButton value="arrows" size="small">
            <ImportExport />
          </ToggleButton>
        </ToggleButtonGroup>
        {editMode === "input" ? (
          <div className={classes.inputEstimate}>
            <TextField
              autoFocus
              type="number"
              label="Your Estimate"
              value={estimate || estimate === 0 ? estimate : ""}
              onChange={(e) => setEstimate(parseInt(e.currentTarget.value))}
            />
          </div>
        ) : (
          <div className={classes.arrowsEstimate}>
            <IconButton size="small" onClick={() => setEstimate(estimate + 1 || estimate + 1 === 0 ? estimate + 1 : 1)}>
              <KeyboardArrowUp />
            </IconButton>
            <Typography variant="h5">{estimate || 0}</Typography>
            <IconButton size="small" onClick={() => setEstimate(estimate - 1 || estimate - 1 === 0 ? estimate - 1 : 1)}>
              <KeyboardArrowDown />
            </IconButton>
          </div>
        )}
        <Button color="primary" onClick={() => props.onSubmitEstimate(estimate)}>
          Submit
        </Button>
      </div>
    </Zoom>
  );
};

export default Estimate;
