import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Translations
import enTranslation from './en.json';
import roTranslation from './ro.json';

const resources = {
  en: {
    translation: enTranslation
  },
  ro: {
    translation: roTranslation
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",

    keySeparator: ".",

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
