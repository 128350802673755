import React, { useState } from "react";
import { makeStyles, Container, Typography, Theme, Button } from "@material-ui/core";
import socketio from "socket.io-client";
import { Redirect } from "react-router-dom";

import { baseUrl } from "settings/BaseUrl";
import { MEETINGS_NAMESPACE } from "settings/constants";

const serverUrl = `${baseUrl}/${MEETINGS_NAMESPACE}`;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  mission: {
    marginBottom: theme.spacing(8),
  },
}));

const Landing: React.FC = () => {
  const [meetingId, setMeetingId] = useState<string | null>(null);

  const [socket] = useState(() => {
    return socketio(serverUrl)
      .on("connect", () => console.log("Socket connected."))
      .on("meeting-created", ({ name }: { name: string }) => {
        // Server returns meetings.id
        const id = name.split(".")[1];
        id ? setMeetingId(id) : console.log("Meeting created, but couldn't read meeting id.");
      });
  });

  const classes = useStyles();

  if (meetingId) {
    return <Redirect to={`/${meetingId}`} />
  }
  return (
    <Container maxWidth="md" className={classes.root}>
      <Typography variant="h2" component="h1" className={classes.mission}>
        Make estimations easy.
      </Typography>
      <Button variant="contained" size="large" color="primary" onClick={() => socket.emit("create-meeting")}>
        Create meeting
      </Button>
    </Container>
  );
};

export default Landing;
