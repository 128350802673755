import React from "react";
import { Theme, makeStyles, Box } from "@material-ui/core";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

interface TimerBoxProps {
  isPlaying?: boolean;
  progressValue?: number;
  text?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "200px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  cyclingTimer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
  },
  controlledTimer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  timer: {
    width: "150px",
    height: "150px",
  },
}));

const TimeBoxWrapper: React.FC<TimerBoxProps> = props => {
  const classes = useStyles();

  return (
    <Box p={2} className={classes.root}>
      <div className={classes.cyclingTimer}>
        <div className={classes.controlledTimer}>
          <div className={classes.timer}>
            <CircularProgressbar value={props.progressValue!} text={props.text} />
          </div>
        </div>
      </div>
    </Box>
  )
}

export default TimeBoxWrapper;