import React from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  createMuiTheme,
  MuiThemeProvider,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import red from "@material-ui/core/colors/red";
import blue from "@material-ui/core/colors/blue";

import Navigation from "components/navigation/Navigation";
import Landing from "pages/Landing";
import Meeting from "pages/Meeting";

const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      light: blue[100],
      main: blue[200],
      dark: blue[400],
    },
    secondary: {
      light: red[100],
      main: red[200],
      dark: red[400],
    },
  },
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flexGrow: 1,
  },
}));

const App: React.FC = () => {
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <div className={classes.root}>
        <Router>
          <Navigation />
          <Spacer height="5vh" />
          <div className={classes.content}>
            <Switch>
              <Route path="/:meetingId">
                <Meeting />
              </Route>
              <Route path="/">
                <Landing />
              </Route>
            </Switch>
          </div>
        </Router>
      </div>
    </MuiThemeProvider>
  );
};

interface SpacerProps {
  height: string;
}

const Spacer: React.FC<SpacerProps> = (props: SpacerProps) => {
  return (
    <div style={{width: "100%", height: props.height}}></div>
  )
}

export default App;
