import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Container, Typography, Theme, Button, Grid, TextField, Card } from "@material-ui/core";
import { FileCopy } from "@material-ui/icons";
import socketio from "socket.io-client";
import { useParams } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";

import { baseUrl } from "settings/BaseUrl";
import { MEETINGS_NAMESPACE } from "settings/constants";
import Timer from "components/timer/Timer";
import Control from "components/control/Control";
import Estimate from "components/estimate/Estimate";

const serverUrl = `${baseUrl}/${MEETINGS_NAMESPACE}`;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  pageItem: {
    marginTop: theme.spacing(10),
    width: "100%",
  },
  participant: {
    width: "100%",
    height: "100%",
    minHeight: "150px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    textAlign: "center",
  },
  meetingNotFound: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

interface Meeting {
  name: string;
  roundTime: number;
  inProgress: boolean;
  roundStartTime: Date | null;
  participants: Array<Participant>;
}

interface Participant {
  name: string;
  estimation: number | null;
}

const Meeting: React.FC = () => {
  const { meetingId } = useParams();
  const meetingName = `${MEETINGS_NAMESPACE}.${meetingId}`;
  const publicUrl = `${window.location.host}/${meetingId}`;

  const [meeting, setMeeting] = useState<Meeting | null>(null);
  const [meetingNotFound, setMeetingNotFound] = useState(false);

  const [hasJoined, setHasJoined] = useState(false);
  const [name, setName] = useState("");

  const [socket, setSocket] = useState<SocketIOClient.Socket | null>(null);

  useEffect(() => {
    const socket = socketio(serverUrl);

    socket.on("connect", () => {
      socket.emit("watch-meeting", meetingName);
      // This should gracefully handle disconnects.
      if (hasJoined && name) {
        socket.emit("join-meeting", meetingName, name);
      }
    });

    socket.on("meeting-updated", (meeting: Meeting) => {
      console.log("Got server update");
      console.log(meeting);
      setMeeting(meeting);
    });

    socket.on("meeting-not-found", () => {
      setMeetingNotFound(true);
    });

    setSocket(socket);
  }, []);

  const join = () => {
    socket?.emit("join-meeting", meetingName, name);
    setHasJoined(true); // ...meh?
  };

  const leave = () => {
    socket?.emit("leave-meeting", meetingName);
    setHasJoined(false); // ...meh?
  };

  const submitEstimate = (estimate: number) => {
    socket?.emit("estimate", estimate, meetingName);
  };

  const [remainingSeconds, setRemainingSeconds] = useState(0);
  useInterval(() => {
    if (meeting && meeting.inProgress && meeting.roundStartTime) {
      setRemainingSeconds(meeting.roundTime - Math.round((+new Date() - +new Date(meeting.roundStartTime)) / 1000));
    } else {
      setRemainingSeconds(meeting?.roundTime || 0);
    }
  }, 200);

  const toggleInProgress = () => {
    if (meeting) {
      if (meeting.inProgress) {
        socket?.emit("end-round", meetingName);
      } else {
        socket?.emit("start-round", meetingName);
      }
    }
  };

  const updateRoundTime = (howMuch: number) => {
    if (meeting) {
      socket?.emit("update-round-time", meeting.name, meeting.roundTime + howMuch);
    }
  };

  console.log("render()", meeting);
  const classes = useStyles();

  if (meetingNotFound) {
    return (
      <Container maxWidth="md" className={classes.meetingNotFound}>
        <Typography variant="h5">
          The meeting you're looking for couldn't be found. This may happen because the URL is incomplete, or the
          meeting doesn't exist anymore. Meetings are destroyed after 1 hour of inactivity.
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" className={classes.root}>
      <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h4">Copy meeting link</Typography>
        <CopyToClipboard text={publicUrl}>
          <Button>
            <FileCopy />
          </Button>
        </CopyToClipboard>
      </div>
      <div className={classes.pageItem}>
        <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <TextField
            type="text"
            label="Your name"
            name="name"
            autoComplete="fname"
            onChange={(e) => setName(e.target.value)}
            value={name}
            helperText={hasJoined ? "You have joined the meeting" : "You are watching the meeting"}
            placeholder="Leave empty if you are just watching"
            variant="outlined"
            required
            autoFocus
            disabled={hasJoined}
            style={{ width: "300px" }}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === "Enter" && name) join();
            }}
          />
          <Button color="primary" onClick={hasJoined ? leave : join} disabled={!hasJoined && !name}>
            {hasJoined ? "Leave" : "Join"}
          </Button>
        </div>
      </div>
      <div className={classes.pageItem}>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} sm={4}>
            <Control
              show={hasJoined}
              roundTimeSeconds={meeting?.roundTime || 0}
              inProgress={meeting?.inProgress || false}
              onIncrementMinutesButtonClick={() => updateRoundTime(60)}
              onDecrementMinutesButtonClick={() => updateRoundTime(-60)}
              onIncrementSecondsButtonClick={() => updateRoundTime(1)}
              onDecrementSecondsButtonClick={() => updateRoundTime(-1)}
              toggleInProgress={toggleInProgress}
            ></Control>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Timer
              isPlaying={true}
              progressValue={(remainingSeconds * 100) / (meeting?.roundTime || 1)}
              text={`${remainingSeconds}`}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Estimate show={hasJoined} onSubmitEstimate={submitEstimate} />
          </Grid>
        </Grid>
      </div>
      <div className={classes.pageItem}>
        {meeting?.participants.length === 0 ? (
          <Typography variant="h3" align="center">
            There are no participants
          </Typography>
        ) : (
          <Grid container justify="flex-start" spacing={2}>
            {meeting?.participants.map((participant: Participant) => (
              <Grid key={participant.name} item xs={6} sm={4}>
                <Card className={classes.participant}>
                  <Typography variant="h3">{meeting.inProgress ? "?" : participant.estimation}</Typography>
                  <Typography variant="h5">{participant.name}</Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </Container>
  );
};

function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current) savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default Meeting;
